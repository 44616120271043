import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import routesMap from '../../Routes';
import Dots from './Dots';
import { Title1, Title2 } from './homeStyledComponents';
import { Button } from './v2/StyledComponents';
import { colors, mobileThresholdPixels } from '../styledComponents';
import NumberedBlock from './NumberedBlock';
import about1 from '../../assets/about1.jpg';
import about2 from '../../assets/about2.png';
import about3 from '../../assets/about3.jpg';
import about4 from '../../assets/about4.jpg';

const Container = styled.div`
  padding: 20px 100px;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.white ? colors.white : colors.lightGrey};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 5px;
  }
`;

const Column = styled.div`
  flex: 1;
  margin: 40px 100px;
  display: flex;
  flex-direction: column;
  text-align: justify;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 5px 15px;
    max-width: 100%;
  }
`;

const P = styled.p`
  text-align: center;
  color: ${colors.navy};
  ${(props) => props.center && 'text-align: center;'}
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${(props) => props.columnOnMobile && `
    @media (max-width: ${mobileThresholdPixels}) {
      flex-direction: column;
    }
  `}
`;

const ImgContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  object-fit: cover;
  height: 600px;
`;

const renderIdea = () => (
  <Row columnOnMobile>
    <Column>
      <Row>
        <Title2>Tilli, d‘où vient l‘idée ?</Title2>
      </Row>
      <P>
        Beryl, la co-fondatrice de Tilli, avait besoin de
        retoucher une robe pour un événement, comme
        beaucoup de personnes.
      </P>
      <P>
        À première vue, elle n’avait qu’une solution
        possible : la retoucherie en bas de chez elle qui
        n’était pas vraiment accueillante, ni gage de
        qualité.
      </P>
      <P>
        Elle en a donc parlé un peu autour d’elle pour
        trouver une autre solution et une personne de son
        entourage lui a recommandé une couturière qui
        s’est déplacée à son domicile exceptionnellement.
        Ce moment passé avec cette couturière a vraiment
        été une expérience riche d’un échange humain et
        d’une expertise rassurante.
      </P>
      <P>
        Beryl, enchantée par la magie de ce moment, a
        créé Tilli dans le but de rendre l’accès à ce service
        plus simple et plus rapide
      </P>
    </Column>
    <ImgContainer>
      <Img src={about1} alt="Une retoucherie à Paris pour cintrer une jupe" />
    </ImgContainer>
  </Row>
);

const renderTeam = () => (
  <div>
    <Title2>L‘équipe</Title2>
    <Row columnOnMobile>
      <NumberedBlock title="Antoinette" alt="Machine à coudre pour faire un ourlet à un pantalon">
        <P>
          Un peu psycho des chiffres et du suivi des commandes,
          son rire contraste. Elle fait tourner la machine !
        </P>
      </NumberedBlock>
      <NumberedBlock title="Benjamin" alt="Un retoucheur à Paris qui cintre une pantalon">
        <P>
          Il calme le tempo dans les bureaux par un doux son de
          ligne de code sur son clavier au même rythme qu‘une machine à coudre.
        </P>
      </NumberedBlock>
      <NumberedBlock title="Beryl" alt="Retoucherie parisienne">
        <P>
          Débordante de nouveaux projets et de nouvelles
          signatures, elle influe une énergie qu‘il faut savoir suivre…
        </P>
      </NumberedBlock>
    </Row>
    <Row>
      <Img src={about2} alt="Une retoucherie à Paris pour cintrer une jupe" />
    </Row>
  </div>
);

const renderMission = () => (
  <Row columnOnMobile>
    <ImgContainer>
      <Img src={about3} alt="Une retoucherie à Paris pour cintrer une jupe" />
    </ImgContainer>
    <Column>
      <Row>
        <Title2>Chez Tilli, notre mission est de vous bichonner !</Title2>
      </Row>
      <P>
        Chez Tilli nous avons décidé de vous bichonner en
        adaptant aux besoins d‘aujourd‘hui, le métier
        artisanal du couturier
      </P>
      <P>
        Tilli, c‘est le premier service de couturiers à domicile.
        Du simple jean troué ou trop long, à la robe fétiche
        qui aurait besoin d’un petit coup de jeune , Tilli sera
        votre parfait allié pour retoucher, réajuster ou
        transformer votre garde-robe, le tout en vivant une
        expérience personnalisée et humainement riche !
      </P>
      <P>
        La cerise sur le gâteau?
        Vous vous libérez du temps, et vous consommez vos
        vêtements différemment, de façon plus durable.
      </P>
      <Row>
        <Link to={routesMap.Step1.url}>
          <Button large>{'>> réserver <<'}</Button>
        </Link>
      </Row>
    </Column>
  </Row>
);

const renderTilliste = () => (
  <Row columnOnMobile>
    <Column>
      <Row>
        <Title2>Vous bichonner grâce à nos Tillistes</Title2>
      </Row>
      <P>
        Vous devez vous demander «nos quoi?».
      </P>
      <P>
        On a créé le terme «Tilliste» pour appuyer la multitude
        des savoir-faire des couturiers qui ont rejoint
        l‘aventure Tilli.
      </P>
      <P>
        Couturiers, retoucheurs, modélistes & stylistes, rattachés
        souvent à des grandes marques, doués en
        confection et dotés d‘une grande imagination !
        Nos Tillistes sont donc des experts métiers, ou encore
        comme nous aimons les appeler, des artisans professionnels,
        gage de savoir-faire et de qualité !
      </P>
    </Column>
    <ImgContainer>
      <Img src={about4} alt="Une retoucherie à Paris pour cintrer une jupe" />
    </ImgContainer>
  </Row>
);

const About = () => (
  <div>
    <Container>
      <Column>
        <Title1>L‘histoire de Tilli</Title1>
        <Row>
          <P narrow>
            Chez Tilli, nous partageons tous un objectif commun : celui de remettre au goût
            du jour le métier de couturier en le digitalisant. Le couturier échange directement
            avec le client pour ne pas perdre en qualité et laisser place à une expérience unique !
          </P>
        </Row>
      </Column>
    </Container>
    <Container white>
      <Dots center />
      {renderIdea()}
    </Container>
    <Container>
      <Dots center />
      {renderTeam()}
    </Container>
    <Container white>
      <Dots center />
      {renderMission()}
    </Container>
    <Container>
      {renderTilliste()}
    </Container>
  </div>
);

export default About;
